import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import RootElement from './src/components/root-element';

// Globale CSS wordt hier geïmporteerd
import './src/styles/global.css';

// wrapRootElement: zorgt voor consistentie in de hele app
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
