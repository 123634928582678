import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import {
  LocalStorageProvider,
  FetchProvider as FetchBaseProvider,
  AuthProvider,
  useAuth,
} from '@reactivers/hooks';
import React, { PropsWithChildren } from 'react';

// Definiëren van het thema
const theme = createTheme({
  palette: {
    mode: 'light', // Stel lichte modus in, pas aan naar 'dark' als dat nodig is
  },
});

const RootElement = ({ children }: PropsWithChildren) => {
  return (
    <LocalStorageProvider>
      <AuthProvider>
        <FetchProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </FetchProvider>
      </AuthProvider>
    </LocalStorageProvider>
  );
};

const FetchProvider = ({ children }: PropsWithChildren) => {
  const { token, isLoggedIn } = useAuth();

  return (
    (token || !isLoggedIn) && (
      <FetchBaseProvider
        url={process.env.GATSBY_API_URL}
        getAuthorizationHeader={() => {
          return `Bearer ${token}`;
        }}
      >
        {children}
      </FetchBaseProvider>
    )
  );
};

export default RootElement;
